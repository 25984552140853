import React, { useState } from 'react';

const questions = [
  // GAD-7 questions
  { id: 'gad1', text: 'Feeling nervous, anxious, or on edge', category: 'GAD-7' },
  { id: 'gad2', text: 'Not being able to stop or control worrying', category: 'GAD-7' },
  { id: 'gad3', text: 'Worrying too much about different things', category: 'GAD-7' },
  { id: 'gad4', text: 'Trouble relaxing', category: 'GAD-7' },
  { id: 'gad5', text: 'Being so restless that it\'s hard to sit still', category: 'GAD-7' },
  { id: 'gad6', text: 'Becoming easily annoyed or irritable', category: 'GAD-7' },
  { id: 'gad7', text: 'Feeling afraid as if something awful might happen', category: 'GAD-7' },
  
  // PHQ-9 questions
  { id: 'phq1', text: 'Little interest or pleasure in doing things', category: 'PHQ-9' },
  { id: 'phq2', text: 'Feeling down, depressed, or hopeless', category: 'PHQ-9' },
  { id: 'phq3', text: 'Trouble falling or staying asleep, or sleeping too much', category: 'PHQ-9' },
  { id: 'phq4', text: 'Feeling tired or having little energy', category: 'PHQ-9' },
  { id: 'phq5', text: 'Poor appetite or overeating', category: 'PHQ-9' },
  { id: 'phq6', text: 'Feeling bad about yourself - or that you are a failure or have let yourself or your family down', category: 'PHQ-9' },
  { id: 'phq7', text: 'Trouble concentrating on things, such as reading the newspaper or watching television', category: 'PHQ-9' },
  { id: 'phq8', text: 'Moving or speaking so slowly that other people could have noticed. Or the opposite - being so fidgety or restless that you have been moving around a lot more than usual', category: 'PHQ-9' },
  { id: 'phq9', text: 'Thoughts that you would be better off dead, or of hurting yourself', category: 'PHQ-9' },
];

const options = [
  { value: 0, label: 'Not at all' },
  { value: 1, label: 'Several days' },
  { value: 2, label: 'More than half the days' },
  { value: 3, label: 'Nearly every day' },
];

const Questionnaire = ({ onComplete }) => {
  const [answers, setAnswers] = useState({});
  const [currentQuestion, setCurrentQuestion] = useState(0);

  const handleAnswer = (value) => {
    const newAnswers = { ...answers, [questions[currentQuestion].id]: value };
    setAnswers(newAnswers);
    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      const scores = calculateScores(newAnswers);
      onComplete(newAnswers, scores);
    }
  };

  const calculateScores = (answers) => {
    let gadScore = 0;
    let phqScore = 0;
    Object.entries(answers).forEach(([key, value]) => {
      if (key.startsWith('gad')) gadScore += value;
      if (key.startsWith('phq')) phqScore += value;
    });
    return { gad7: gadScore, phq9: phqScore };
  };

  const currentQ = questions[currentQuestion];

  return (
    <div className="max-w-2xl mx-auto mt-10">
      <h2 className="text-2xl font-bold mb-4">{currentQ.category}</h2>
      <p className="mb-4">Question {currentQuestion + 1} of {questions.length}</p>
      <p className="mb-4">{currentQ.text}</p>
      <div className="space-y-2">
        {options.map((option) => (
          <button
            key={option.value}
            onClick={() => handleAnswer(option.value)}
            className="w-full p-2 text-left hover:bg-gray-100 rounded"
          >
            {option.label}
          </button>
        ))}
      </div>
      <div className="mt-8">
        <h3 className="text-xl font-bold mb-2">Your Answers:</h3>
        <table className="w-full border-collapse border border-gray-300">
          <thead>
            <tr>
              <th className="border border-gray-300 p-2">Question</th>
              <th className="border border-gray-300 p-2">Answer</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(answers).map(([key, value]) => (
              <tr key={key}>
                <td className="border border-gray-300 p-2">{questions.find(q => q.id === key).text}</td>
                <td className="border border-gray-300 p-2">{options[value].label}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Questionnaire;