import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const ResultsVisualizationComponent = ({ scores, historicalData }) => {
  const getGAD7Severity = (score) => {
    if (score >= 15) return 'Severe anxiety';
    if (score >= 10) return 'Moderate anxiety';
    if (score >= 5) return 'Mild anxiety';
    return 'Minimal anxiety';
  };

  const getPHQ9Severity = (score) => {
    if (score >= 20) return 'Severe depression';
    if (score >= 15) return 'Moderately severe depression';
    if (score >= 10) return 'Moderate depression';
    if (score >= 5) return 'Mild depression';
    return 'Minimal depression';
  };

  return (
    <div className="w-full max-w-4xl mx-auto mt-10 space-y-6 p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-6 text-center">Your Mental Health Overview</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-blue-50 p-6 rounded-lg shadow">
          <h3 className="text-xl font-bold mb-2">GAD-7 Score: {scores.gad7}</h3>
          <p className="text-lg">{getGAD7Severity(scores.gad7)}</p>
        </div>
        <div className="bg-green-50 p-6 rounded-lg shadow">
          <h3 className="text-xl font-bold mb-2">PHQ-9 Score: {scores.phq9}</h3>
          <p className="text-lg">{getPHQ9Severity(scores.phq9)}</p>
        </div>
      </div>

      {historicalData.length > 1 ? (
        <div className="mt-10">
          <h2 className="text-2xl font-bold mb-6 text-center">Your Progress Over Time</h2>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={historicalData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="gad7" stroke="#4299e1" name="GAD-7" strokeWidth={2} />
              <Line type="monotone" dataKey="phq9" stroke="#48bb78" name="PHQ-9" strokeWidth={2} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <div className="mt-10 text-center">
          <p className="text-lg">Complete more assessments to see your progress over time.</p>
        </div>
      )}
    </div>
  );
};

export default ResultsVisualizationComponent;