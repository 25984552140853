import React, { useState, useEffect } from 'react';
import Login from './components/Login';
import Questionnaire from './components/Questionnaire';
import ResultsVisualizationComponent from './components/ResultsVisualizationComponent';
import IntroductionScreen from './components/IntroductionScreen';
import Layout from './components/Layout';

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentView, setCurrentView] = useState('login');
  const [scores, setScores] = useState({ gad7: 0, phq9: 0 });
  const [historicalData, setHistoricalData] = useState([]);

  useEffect(() => {
    const storedData = localStorage.getItem('historicalData');
    if (storedData) {
      setHistoricalData(JSON.parse(storedData));
    }
  }, []);

  const handleLogin = (password) => {
    setIsLoggedIn(true);
    setCurrentView('introduction');
  };

  const handleStartQuestionnaire = () => {
    setCurrentView('questionnaire');
  };

  const handleQuestionnaireComplete = (answers, newScores) => {
    setScores(newScores);
    const newEntry = {
      date: new Date().toISOString().split('T')[0],
      ...newScores
    };
    const updatedHistoricalData = [...historicalData, newEntry];
    setHistoricalData(updatedHistoricalData);
    localStorage.setItem('historicalData', JSON.stringify(updatedHistoricalData));
    setCurrentView('results');
  };

  return (
    <Layout>
      {!isLoggedIn && <Login onLogin={handleLogin} />}
      {isLoggedIn && currentView === 'introduction' && <IntroductionScreen onStart={handleStartQuestionnaire} />}
      {currentView === 'questionnaire' && <Questionnaire onComplete={handleQuestionnaireComplete} />}
      {currentView === 'results' && <ResultsVisualizationComponent scores={scores} historicalData={historicalData} />}
    </Layout>
  );
};

export default App;