import React from 'react';

const IntroductionScreen = ({ onStart }) => {
  return (
    <div className="max-w-2xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-4">Welcome to the Mental Health Tracker</h1>
      <p className="mb-4">
        This app helps you monitor your mental health using two standardized questionnaires:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>GAD-7 (Generalized Anxiety Disorder-7)</li>
        <li>PHQ-9 (Patient Health Questionnaire-9)</li>
      </ul>
      <p className="mb-4">
        Your responses are stored securely on your device and are not shared with anyone unless you choose to do so.
      </p>
      <p className="mb-6">
        The questionnaire will take about 5-10 minutes to complete. Are you ready to begin?
      </p>
      <button 
        onClick={onStart}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Start Questionnaire
      </button>
    </div>
  );
};

export default IntroductionScreen;